import criteria from '~/composables/criteria/usePageApiParams.json';
import { ApiClientError } from '@shopware/api-client';
import { AxiosError } from 'axios';
import type { RequestReturnType } from '#shopware';
import type { AhProduct } from '~/types/models/product/product';
import type { AhBreadcrumb } from '~/types/models/page';

/*
uses custom /store-api/pwa/page endpoint to get cms page from a seo url with only 1 request
(instead of 2 requests to /store-api/seo-url and /store-api/category/xy)
 */
export function usePage() {
    const { apiClient } = useShopwareContext();

    const route = useRoute();

    const { locales } = useI18n();
    const localeCodes = computed(() => locales.value.map((locale) => locale.code));

    const cleanedPath = computed(() => getPathWithoutLang(route.path));
    const page = computed<RequestReturnType<'pwaResolvePage'> | null>(
        () => useNuxtData(`page-${cleanedPath.value}`).data.value,
    );

    const product = computed(() => page.value?.product as AhProduct | null);
    const seoUrlForeignKey = computed(() => page.value?.resourceIdentifier);

    const getPathWithoutLang = (path: string): string => {
        path = decodeURIComponent(path);

        if (localeCodes.value?.length) {
            const localeRegExp = new RegExp(`^/(${localeCodes.value.join('|')})(/|$)`);

            path = path.replace(localeRegExp, '');
        }

        const isTechnicalUrl =
            path.startsWith('navigation/') || path.startsWith('detail/') || path.startsWith('landingPage/');

        if (isTechnicalUrl) {
            path = '/' + path; // technical url needs slash at the beginning
        }

        return path;
    };

    const getPage = async (path: string): Promise<RequestReturnType<'pwaResolvePage'> | undefined> => {
        try {
            const categoryId = route.query.c as string | undefined;
            const response = await apiClient.invoke('pwaResolvePage post /pwa/page sw-include-seo-urls', {
                ...criteria,
                path,
                'sw-include-seo-urls': true,
                ...(categoryId ? { c: categoryId } : {}),
            });

            if (path === '' && response?.breadcrumbs?.length) {
                response.breadcrumbs = [];
            }

            return response;
        } catch (error) {
            if (error instanceof ApiClientError) {
                throw createError({ statusCode: error.status, statusMessage: error.message });
            } else if (error instanceof AxiosError) {
                throw createError({ statusCode: parseInt(error.code || ''), statusMessage: error.message });
            } else if (error instanceof Error) {
                throw error;
            }
        }
    };

    const pageBreadcrumbs = computed<AhBreadcrumb[]>(
        () =>
            page.value?.breadcrumbs?.map(({ name, path, seoPath }) => ({ name, path, seoPath }) as AhBreadcrumb) || [],
    );

    return {
        getPathWithoutLang,
        getPage,
        cleanedPath,
        page,
        product,
        seoUrlForeignKey,
        pageBreadcrumbs,
    };
}
